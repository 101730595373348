export const backendUrl = "http://9275d5424247.ngrok.io/";

// import Constants from "expo-constants";
// const { manifest } = Constants;

// const api =
//   typeof manifest.packagerOpts === `object` && manifest.packagerOpts.dev
//     ? manifest.debuggerHost.split(`:`).shift().concat(`:3000`)
//     : `api.example.com`;

// console.log(api);
