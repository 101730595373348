import React, { Component, Suspense } from "react";
import Layout from "./components/Layout/";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";
import moment from "moment";
import "moment/locale/es";
// Import Css
import "./assets/css/materialdesignicons.min.css";
import "./Apps.scss";

import "./assets/css/colors/red.css";
import PageError from "./pages/Pages/Special/PageError";
import PaymentMediumPage from "./pages/PaymentMedium/paymentMedium.page";
moment.locale("es");

// Include Routes
// import routes from "./routes";
const ClassicApp = React.lazy(() =>
  import("./pages/Classic Application/index")
);

const EventPage = React.lazy(() => import("./pages/Event/index"));

const ShopCart = React.lazy(() => import("./pages/Pages/Shop/ShopCart"));

function withLayout(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return (
        <Layout>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

class App extends Component {
  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <React.Fragment>
        <Router>
          <Suspense fallback={this.Loader()}>
            <Switch>
              {/* {routes.map((route, idx) =>
                route.isWithoutLayout ? (
                  <Route
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                    key={idx}
                  />
                ) : (
                  <Route
                    path={route.path}
                    exact
                    component={withLayout(route.component)}
                    key={idx}
                  />
                )
              )} */}
              <Route
                path={"/"}
                exact
                component={withLayout(ClassicApp)}
                key={1}
              />
              <Route
                exact
                path="/newPaymentMedium/:paymentMediumAttemptId"
                render={() => <PaymentMediumPage />}
              />
              <Route
                exact
                path="/event/:eventId"
                component={withLayout(EventPage)}

                // render={() => <EventPage />}
              />
              <Route
                exact
                path="/event/:eventId/checkout"
                component={withLayout(ShopCart)}

                // render={() => <EventPage />}
              />
              <Route component={PageError} />
            </Switch>
          </Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
