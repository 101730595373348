import axios from "axios";
import { backendUrl } from "./env";

// import { storage } from "./localStorage.service";

const http = (headers) => {
  // console.log(backendUrl);
  return axios.create({ baseURL: backendUrl, headers });
};

class TbkService {
  async checkTbkTokenResponse(id) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const https = http(headers);
    return https.post(`tbk/checkTbkTokenResponse/${id}`);
  }
}

export const tbkService = new TbkService();
