import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { tbkService } from "../../services/tbk.service";
import { Container, Row, Col } from "reactstrap";

export default function PaymentMediumPage() {
  let { paymentMediumAttemptId } = useParams();
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const response = await tbkService.checkTbkTokenResponse(
          paymentMediumAttemptId
        );

        if (response.data.tbkResponse === "0") {
          setMessage(
            "Tarjeta agregada correctamente. Ya puedes volver a la app."
          );
          setLoading(false);
        } else {
          setMessage(
            "Ocurrió un error, el medio de pago no ha sido creado, intenta nuevamente o con otro. Si el problema persiste escríbenos a soporte@mambo.social"
          );
          setLoading(false);
          setError(true);
        }
      } catch (error) {
        setMessage(
          "Ocurrió un error, el medio de pago no ha sido creado, intenta nuevamente o con otro. Si el problema persiste escríbenos a soporte@mambo.social"
        );
        setLoading(false);
        setError(true);
      }
    })();
  }, [paymentMediumAttemptId]);

  return (
    <React.Fragment>
      <section className="bg-home bg-light d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="text-center">
                <div
                  className="icon d-flex align-items-center justify-content-center bg-soft-primary rounded-circle mx-auto"
                  style={{ height: "90px", width: "90px" }}
                >
                  {!error ? (
                    <i className="uil uil-thumbs-up align-middle h1 mb-0"></i>
                  ) : (
                    <i className="uil uil-surprise align-middle h1 mb-0"></i>
                  )}
                </div>
                {loading ? (
                  <h1 className="my-4 font-weight-bold">Cargando...</h1>
                ) : error ? (
                  <h1 className="my-4 font-weight-bold">Error</h1>
                ) : (
                  <h1 className="my-4 font-weight-bold">Listo</h1>
                )}

                <p className="text-muted para-desc mx-auto">{message}</p>
                {/* <Link to="index" className="btn btn-soft-primary mt-3">
                  Go To Home
                </Link> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}
