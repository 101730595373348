import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";

// Scroll up button
import ScrollUpButton from "react-scroll-up-button";

//Import Switcher
// import ThemeSwitcher from "./ThemeSwitcher";

//Import Icons
import FeatherIcon from "feather-icons-react";
//Reactstrap
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from "reactstrap";

// Layout Components
const Topbar = React.lazy(() => import("./Topbar"));
const NavbarPage = React.lazy(() =>
  import("../../pages/Saas Onepage/NavbarPage")
);
const Footer = React.lazy(() => import("./Footer"));
// const FooterWithoutMenuLightSocialOnly = React.lazy(() =>
//   import("./FooterWithoutMenuLightSocialOnly")
// );

const CustomDot = () => {
  return (
    <i>
      <FeatherIcon icon="arrow-up" className="icons" />
    </i>
  );
};

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ modal: true });
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const childrenWithProps = React.Children.map(
      this.props.children,
      (child) => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { onOpenModal: this.openModal });
        }
        return child;
      }
    );
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modal}
          role="dialog"
          autoFocus={true}
          centered={true}
        >
          <ModalHeader toggle={this.togglemodal}>¡Contáctanos!</ModalHeader>
          <ModalBody>
            <div className="bg-white p-3 rounded box-shadow">
              <p className="text-muted mb-0">
                ¡Hola! Soy Vicente y te puedo ayudar a crear tu cuenta de
                Productora, solucionar cualquier duda que tengas, coordinar una
                demo, etc. Sólo escríbeme a{" "}
                <span className="text-primary">vicente@mambo.social</span> y me
                pondré en contacto contigo para mostrarte lo que tenemos
                desarrollado.
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => this.setState({ modal: false })}
            >
              ¡Entendido!
            </Button>
            {/* <Button color="primary">Save Changes</Button> */}
          </ModalFooter>
        </Modal>
        <Suspense fallback={this.Loader()}>
          {this.props.location.pathname === "/index-onepage" ? (
            <NavbarPage />
          ) : (
            <Topbar onOpenModal={() => this.openModal()} />
          )}

          {/* {this.props.children} */}
          {childrenWithProps}

          {(() => {
            if (
              this.props.location.pathname === "/index-marketing" ||
              this.props.location.pathname === "/index-digital-agency" ||
              this.props.location.pathname === "/index-modern-business" ||
              this.props.location.pathname === "/index-services" ||
              this.props.location.pathname === "/index-job"
            ) {
              return <Footer isLight={true} />;
            }
            // else if (this.props.location.pathname === "/index-portfolio") {
            //   return (
            //     <FooterWithoutMenuLightSocialOnly className="border-0 bg-light text-dark" />
            //   );
            // } else if (
            //   this.props.location.pathname === "/index-personal" ||
            //   this.props.location.pathname === "/helpcenter-overview" ||
            //   this.props.location.pathname === "/helpcenter-guides" ||
            //   this.props.location.pathname === "/helpcenter-support-request" ||
            //   this.props.location.pathname === "/page-invoice"
            // ) {
            //   return <FooterWithoutMenuLightSocialOnly class="" />;
            // }
            else {
              return <Footer />;
            }
          })()}

          {/* <div className="btn btn-icon btn-soft-primary back-to-top"> */}
          {/* scrollup button */}
          <ScrollUpButton
            ContainerClassName="classForContainer"
            style={{ height: 36, width: 36 }}
            TransitionClassName="classForTransition"
          >
            <CustomDot />
          </ScrollUpButton>
          {/* </div> */}

          {/* theme switcher */}
          {/* <ThemeSwitcher /> */}
        </Suspense>
      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
